<template>
  <div class="mb-24">
    <div class="mt-8 space-y-2">
      <div v-if="(userCategoriesLoaded && userCategories.length === 0) || isOnboardingOpen">
        <p>
          You can use Categories like buckets to group together relevant posts. Here are some
          categories you can create.
        </p>
        <div class="flex mt-4 mb-6 flex-wrap">
          <button
            v-for="(category, index) in availableOnboardingCategories"
            :key="index"
            @click="createOnboardingCategory(category, colors[index], index)"
            class="btn text small secondary mb-3 mr-3"
          >
            <span
              class="color-circle ml-1 mr-3"
              :style="{ 'background-color': colors[index] }"
            ></span>
            <span>{{ category }}</span>
            <!-- plus.svg -->
            <svg
              class="ml-1 transform scale-75 opacity-50"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z"
                data-name="plus"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div data-cy="category-card" class="card category-card empty dark-mode-bg" v-for="category in userCategories" :key="category.id">
        <div class="card-title justify-between">
          <div class="card-name flex-1 flex items-center">
            <span
              class="inline-block rounded-full w-3 h-3 mr-4"
              :style="categoryStyle(category)"
            ></span>
            <router-link
              class="text-base"
              :to="'/recurrent-posts/categories/' + category.id"
              :title="category.name"
              v-bind:class="{
                'max-width-title-with-scheduled-slots-warning': scheduledSlots(category.id),
              }"
            >
              <span
                :class="{ 'text-ds-text-secondary-tertiary': !category.isEnabled }"
                @click="$eventStore.recurrentPosts('Manage Categories: View posts')"
              >
                {{ category.name }}
              </span>
            </router-link>
            <tooltip
              content="Add more tweets to cover your weekly scheduled posts."
              v-if="scheduledSlots(category.id)"
            >
              <svg class="exclamation-circle w-6 h-6 ml-1" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </tooltip>
          </div>
          <div class="card-counter">
            <router-link data-cy="category-card-counter-link" :to="'/recurrent-posts/categories/' + category.id">
              <span
                class="badge"
                @click="$eventStore.recurrentPosts('Manage Categories: View posts')"
              >
                {{ category.numberOfPosts }} Posts
                {{ category.isEnabled ? '' : '- disabled' }}
              </span>
            </router-link>
          </div>
        </div>
          <tooltip
          content="New Post"
          >
        <div class="card-action">
          <base-button
            data-cy="category-card-add-tweet-button"
            class="btn icon transparent dark-mode-bg"
            @click.prevent=""
            @click="
              addTweetToCategory(category);
              $eventStore.recurrentPosts('Manage Categories: New post');
            "
            slot="title-container"
          >
            <inline-svg src="/img/icons/edit.svg" />
          </base-button>
        </div>
          </tooltip>
        <div class="card-action">
          <base-dropdown
            menu-fixed
            class="nav-item"
            tag="div"
            title-tag="button"
            title-classes="nav-link pr-0"
          >
            <base-button
              data-cy="category-card-menu-button"
              class="btn icon transparent dark-mode-bg"
              @click.prevent=""
              slot="title-container"
            >
              <inline-svg class="h-4 w-4" src="/img/icons/moreOptions3dot.svg" />
            </base-button>
            <template>
              <base-button
                data-cy="category-card-menu-edit-button"
                @click="editCategory(category)"
                class="new-dropdown-item dark-mode-dropdown-item"
              >
                <inline-svg src="/img/icons/edit.svg" />
                <span>Edit</span>
              </base-button>
              <router-link
                data-cy="category-card-menu-view-posts-link"
                class="new-dropdown-item dark-mode-dropdown-item"
                :to="'/recurrent-posts/categories/' + category.id"
              >
                <div @click="$eventStore.recurrentPosts('Manage Categories: View posts')">
                  <inline-svg src="/img/icons/filter.svg" />
                  <span>View Posts</span>
                </div>
              </router-link>
              <div
                @click="toggleEnabledStateForCategory(category)"
                class="new-dropdown-item dark-mode-dropdown-item"
                data-cy="category-card-menu-toggle-enable"
              >
                <div>
                  <span>Enabled</span>
                  <base-toggle
                    stateShouldRelyOnValue
                    @input="toggleEnabledStateForCategory(category)"
                    :value="category.isEnabled"
                  />
                </div>
              </div>
              <hr />
              <base-button
                data-cy="category-card-menu-delete-button"
                @click="deleteCategory(category)"
                class="new-dropdown-item dark-mode-dropdown-item delete"
              >
                <inline-svg src="/img/icons/delete.svg" />
                <span>Delete</span>
              </base-button>
            </template>
          </base-dropdown>
        </div>
      </div>
    </div>
    <div class="mt-8 text-center">
      <router-link to="/recurrent-posts/categories/non-categorized">
        View non-categorized posts
      </router-link>
    </div>

    <new-category-modal @close="closeCategoryModal" :show="newCategoryModal" />

    <edit-category-modal
      @close="closeCategoryModal"
      :selectedCategory="editingCategory"
      :show="editCategoryModal"
    />

    <upgrade-to-next-plan-pop-up
      :show="showNextPlanPopUp"
      :content="nextPlanPopUpContent"
      @close="showNextPlanPopUp = false"
      :nextPlan="nextPlan"
    />
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import NewCategoryModal from './Modals/NewCategoryModal';
  import EditCategoryModal from './Modals/EditCategoryModal';
  import CreateCategoryPostMixin from './Mixins/CreateCategoryPostMixin';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import dao from '@/dao';
  import UpgradeToNextPlanPopUp from '@/components/UpgradeToNextPlanPopUp.vue';
  import UpgradeToNextPlanMixin from './Mixins/UpgradeToNextPlanMixin.vue';
  import CategoriesMixin from './Mixins/CategoriesMixin.vue';

  export default {
    components: {
      NewCategoryModal,
      EditCategoryModal,
      UpgradeToNextPlanPopUp,
    },
    props: {
      categoriesWithoutEnoughPosts: Array,
      addNew: Boolean,
    },
    mixins: [CreateCategoryPostMixin, SwalModalMixin, UpgradeToNextPlanMixin, CategoriesMixin],
    data() {
      return {
        categories: [],
        newCategoryModal: false,
        editCategoryModal: false,
        editingCategory: null,
        isOnboardingOpen: false,
        colors: [
          '#CBD5E0',
          '#718096',
          '#F56565',
          '#ED8936',
          '#DD6B20',
          '#ECC94B',
          '#48BB78',
          '#38B2AC',
          '#667EEA',
          '#9F7AEA',
        ],
        onboardingCategories: [
          'Affiliate',
          'Quotes',
          'Questions',
          'Promotions',
          'Blog Posts',
          'Book Reviews',
          'Motivational',
        ],
        onboardingCategoriesUsed: [],
      };
    },
    watch: {
      addNew: {
        immediate: true,
        handler: function (newVal) {
          if (newVal === true) this.toggleAddNewCategory(true);
        },
      },
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['userCategories', 'userCategoriesLoaded']),
      availableOnboardingCategories() {
        const that = this;
        return this.onboardingCategories.filter((category) => {
          return !that.onboardingCategoriesUsed.includes(category);
        });
      },
    },
    methods: {
      categoryStyle(category) {
        const color =
          category.color && category.isEnabled ? category.color : 'var(--color-secondary)';
        return {
          background: color,
        };
      },
      toggleAddNewCategory(value) {
        this.addingNewCategory = value;
        this.newCategoryModal = value;
      },
      closeCategoryModal() {
        this.newCategoryModal = false;
        this.editCategoryModal = null;
        this.$emit('close-add-new');
      },
      createOnboardingCategory(categoryName, color) {
        this.isOnboardingOpen = true;
        const that = this;

        const shouldEnableCategory = !this.didUserReachCategoriesLimit;

        return dao.categories
          .create(categoryName, color, shouldEnableCategory, this.userProfile.uid)
          .then(function () {
            that.onboardingCategoriesUsed.push(categoryName);
          });
      },
      async toggleEnabledStateForCategory(category) {
        const isEnabling = !category.isEnabled;

        if (isEnabling) {
          if (this.didUserReachCategoriesLimit) {
            this.openCategoriesLimitNextPlanPopUp();
            return;
          }
        }

        await dao.categories.toggleEnabledState(category.id, isEnabling);
        this.$notify({ type: 'success', message: 'Category updated successfully.' });
      },
      deleteCategory(category) {
        return this.swalModal({
          title: `Delete Category`,
          text: `If you delete this category, all scheduled posts will be removed from the calendar. Are you sure you want to delete it?`,
          type: 'question',
          showCancelButton: true,
          confirmButtonText: 'Delete Category',
          preConfirm: () => {
            return dao.categories.delete(category.id).then(() => {
              this.$notify({ type: 'success', message: 'Category deleted successfully.' });
            });
          },
        });
      },
      editCategory(category) {
        this.editCategoryModal = true;
        this.editingCategory = category;
      },
      scheduledSlots(categoryId) {
        const category = this.categoriesWithoutEnoughPosts.filter((category) => {
          return category.id == categoryId;
        });
        return category.length == 1 ? category[0] : null;
      },
    },

  };
</script>
<style scoped>
  .text-accent {
    color: var(--color-accent);
  }

  .max-width-title-with-scheduled-slots-warning {
    max-width: calc(100% - 3.5rem) !important;
  }
</style>
