<template>
  <div class="mx-auto" :class="expandCategories && 'max-w-610'">
    <div class="sm:flex-no-wrap mb-6 flex flex-wrap items-center justify-between gap-y-3">
      <div class="title flex items-center gap-x-2 overflow-hidden sm:gap-5">
        <new-button
          v-if="expandCategories"
          type="custom"
          @click.prevent="toggleExpandCategories"
          data-cy="categories-back-button"
        >
          <inline-svg src="/img/icons/back.svg" />
        </new-button>
        <h1>
          <span v-show="!expandCategories">Recurrent Posts</span>
          <span v-show="expandCategories">Categories</span>
        </h1>
      </div>
      <div class="actions w-auto">
        <div v-if="!expandCategories" class="text-right sm:text-left">
          <new-button
            data-cy="manage-categories-button"
            @click.prevent="
              toggleExpandCategories();
              $eventStore.recurrentPosts('Manage Categories');
            "
          >
            Manage Categories
          </new-button>
        </div>
        <div v-else class="flex items-center justify-between sm:justify-start space-x-2">
          <new-button data-cy="categories-new-button" @click="addNewCategory">
            New Category
          </new-button>
        </div>
      </div>
    </div>

    <base-alert
      type="warning"
      radius="rounded-lg"
      class="mb-4"
      v-if="categoriesWithoutEnoughPosts && categoriesWithoutEnoughPosts.length > 0"
    >
      There are categories with not enough posts –
      <a v-if="!expandCategories" href="#" @click.prevent="toggleExpandCategories"
        >click here to add new posts.</a
      >
      <span v-else>add new posts below.</span>
    </base-alert>
    <categories
      class="my-4"
      v-show="expandCategories"
      @close-add-new="addingNewCategory = false"
      :addNew="addingNewCategory"
      :categoriesWithoutEnoughPosts="categoriesWithoutEnoughPosts"
    />
    <div v-show="!expandCategories" class="mt-3">
      <base-alert
        type="warning"
        radius="rounded-lg"
        class="mb-4 mt-4"
        v-if="userCategoriesLimit === 0"
        data-cy="recurrent-subscription-alert"
      >
        <p>You are using Hypefury's {{ userPlan }} plan.</p>
        <p>
          You can add recurrent posts, but they will not be published until you
          <router-link data-cy="recurrent-upgrade-link" to="/billing">grab a Hypefury subscription</router-link>.
        </p>
      </base-alert>

      <div class="mb-4 flex justify-end items-center">
        <div>
          Show only filled hours
        </div>
        <info-tooltip
          content="By enabling this you'll see only hours that have posts scheduled."
        ></info-tooltip>
        <base-toggle
        class="ml-2"
          v-model="showOnlyFilledHours"
          type="checkbox"
          name="show-only-filled-hours"
          id="show-only-filled-hours"
          :disabled="userRecurrentPosts.length === 0"
        />
      </div>
      <div class="card tweet">
        <weekly-schedule
          :events="processedRecurrentPosts"
          :showOnlyFilledHours="showOnlyFilledHours"
          @cell-selected="openAddNewEventModal"
          @event-selected="editExistingEvent"
          @event-moved="eventMoved"
        />
      </div>
    </div>
    <recurrent-post-modal
      @close="closeNewEventModal"
      :day="newEvent.day"
      :hour="newEvent.hour"
      :existingPost="editingEvent"
      :show="addingNewEvent"
    ></recurrent-post-modal>
  </div>
</template>

<script>
  import WeeklySchedule from '../components/WeeklySchedule';
  import Categories from './Categories';
  import RecurrentPostModal from './Modals/RecurrentPostModal';
  import { formatTimeFromHoursAndMinutes } from '../util/formatTime';
  import { mapGetters, mapState } from 'vuex';
  import dao from '@/dao';
  import lodash from 'lodash';
  import CustomerStatusMixin from './Mixins/CustomerStatusMixin.vue';
  import CategoriesMixin from './Mixins/CategoriesMixin.vue';

  export default {
    props: {
      subPage: String,
    },
    components: {
      WeeklySchedule,
      RecurrentPostModal,
      Categories,
    },
    data() {
      return {
        addingNewEvent: false,
        showOnlyFilledHours: false,
        editingEvent: null,
        expandCategories: false,
        addingNewCategory: false,
        newEvent: {
          day: 1,
          hour: 0,
        },
      };
    },
    watch: {
      showOnlyFilledHours(newVal, oldVal) {
        if (!oldVal) return;
        dao.userProfile.updateShowOnlyFilledHours(this.userProfile.uid, newVal);
        if (newVal) {
          this.$eventStore.recurrentPosts('Show only filled hours: Enabled');
        } else {
          this.$eventStore.recurrentPosts('Show only filled hours: Disabled');
        }
      },
      subPage: {
        immediate: true,
        handler: function (newVal) {
          if (newVal == 'categories') {
            this.expandCategories = true;
            return;
          }
          this.expandCategories = false;
        },
      },
      userRecurrentPosts(newVal) {
        if (newVal.length === 0) {
          this.showOnlyFilledHours = false;
        }
      },
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['userCategories', 'userRecurrentPosts']),
      shouldShowNextPlanAlert() {
        const nextPlan = this.getNextPlan('categories');
        // Trial is the last plan, so if the user is on trial getNextPlan won't work as intended
        const isThereANextPlan = this.userProfile.customerStatus !== 'trial' || nextPlan;
        return isThereANextPlan && this.didUserReachCategoriesLimit;
      },
      processedRecurrentPosts() {
        return this.userRecurrentPosts
          .filter((post) => post.category)
          .map((post) => {
            return {
              day: post.day,
              name: post.category.name,
              original: post,
              time: post.time,
              backgroundColor: post.category.color ? post.category.color : 'var(--dropdown)',
              meta: {
                category: post.category,
                id: post.id,
              },
            };
          });
      },
      categoriesWithoutEnoughPosts() {
        const categories = {};
        this.userRecurrentPosts.forEach((post) => {
          if (!post.category) {
            return;
          }
          if (!Object.keys(categories).includes(post.category.id)) {
            categories[post.category.id] = {
              scheduledPostsCount: 0,
              availablePostCount: post.category.numberOfPosts,
              id: post.category.id,
              name: post.category.name,
            };
          }
          categories[post.category.id].scheduledPostsCount++;
        });
        return Object.values(categories).filter(
          (category) => category.availablePostCount < category.scheduledPostsCount
        );
      },
    },
    mounted() {
      this.$eventStore.visitRecurrentPostCalendarPage();
      if (!lodash.isNil(this.userProfile.settings.showOnlyFilledHours)) {
        this.showOnlyFilledHours = this.userProfile.settings.showOnlyFilledHours;
      }
    },
    methods: {
      addNewCategory() {
        this.addingNewCategory = true;
      },
      openAddNewEventModal(day, hour) {
        this.addingNewEvent = true;
        this.newEvent.day = day;
        this.newEvent.hour = hour;
      },
      async eventMoved(event, newHour, newDay) {
        if (newDay == event.day && event.time.split(':')[0] == newHour) {
          return;
        }
        const time = formatTimeFromHoursAndMinutes(newHour, 0);
        await dao.recurrentPosts.update(event.original.id, newDay, time);
      },
      closeNewEventModal() {
        this.editingEvent = null;
        this.addingNewEvent = false;
      },
      editExistingEvent(event) {
        this.editingEvent = event;
        this.addingNewEvent = true;
      },
      toggleExpandCategories() {
        this.expandCategories = !this.expandCategories;
        if (this.expandCategories) {
          this.$router.push('/recurrent-posts/categories');
        } else {
          this.$router.push('/recurrent-posts');
        }
      },
    },
    mixins: [CustomerStatusMixin, CategoriesMixin],
  };
</script>
