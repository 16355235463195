<template>
  <td
    class="p-1 border-l border-table hover:bg-dark h-full slot"
    :class="{ 'bg-table': isDraggedOver }"
    @drop="drop($event, hour, dayIndex)"
    @dragover="dragOver"
    @dragleave="dragLeave"
  >
    <div class="flex flex-col justify-center items-center space-y-1 h-full">
      <div
        class="w-full"
        v-for="event in events"
        @click="editEvent(event)"
        draggable="true"
        @dragstart="dragStart(event, $event)"
        @dragend="dragEnd"
        :key="event.meta.id"
      >
        <div
          class="w-full rounded-sm bg-card cursor-pointer text-xs p-2 break-words lg:text-center hover:bg-dropdown"
          :style="eventStyle(event)"
        >
          <p>
            {{ event.name }}
          </p>
          <span>{{ formatTimeWithUserLocale(formatTime(event.time)) }}</span>
        </div>
      </div>
      <div
        class="new flex w-full h-full rounded-sm p-3 text-white text-center cursor-pointer bg-accent"
        @click="selectCell(dayIndex, hour)"
      >
        <!-- plus.svg -->
        <svg
          class="m-auto"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z"
            data-name="plus"
          ></path>
        </svg>
      </div>
    </div>
  </td>
</template>

<script>
  import TimeFormatterMixin from '@/views/Mixins/TimeFormatterMixin';
  import tinycolor from 'tinycolor2';
  import moment from 'moment';

  export default {
    props: {
      events: {
        type: Array,
      },
      hour: {
        type: Number,
      },
      dayIndex: {
        type: Number,
      },
      draggedEvent: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        isDraggedOver: false,
      };
    },
    methods: {
      dragEnd() {
        this.isDraggedOver = false;
      },
      dragOver(event) {
        event.preventDefault();
        this.isDraggedOver = true;
      },
      dragLeave() {
        this.isDraggedOver = false;
      },
      dragStart(slotEvent, dragEvent) {
        this.$emit('event-dragged', slotEvent);
      },
      drop(event, hour, dayIndex) {
        this.isDraggedOver = false;
        this.$emit('event-moved', hour, dayIndex);
        this.$eventStore.recurrentPosts('Calendar: Moved event');
      },
      editEvent(event) {
        this.$emit('edit-event', event);
        this.$eventStore.recurrentPosts('Calendar: Edit event');
      },
      eventStyle(event) {
        const style = {
          'background-color': event.backgroundColor,
        };
        if (event.backgroundColor.charAt(0) !== '#') {
          return style;
        }
        const brightness = tinycolor(event.backgroundColor).getBrightness();
        if (brightness < 148) {
          style.color = '#fff';
        }
        return style;
      },
      formatTime(time) {
        const parts = time.split(':');
        return moment({ hour: parts[0], minute: parts[1] });
      },
      selectCell(dayIndex, hour) {
        this.$emit('cell-selected', dayIndex, hour);
        this.$eventStore.recurrentPosts('Calendar: Select cell');
      },
    },
    mixins: [TimeFormatterMixin],
  };
</script>
