<template>
  <modal :show="show" @close="close">
    <div data-cy="category-modal-header" slot="header" class="modal-title">
      Create a Category
    </div>

    <div>
      <form role="form" @submit.prevent="saveNewCategory" class="needs-validation">
        <div class="space-y-3">
          <input
            data-cy="category-modal-input"
            class="form-control"
            autofocus
            type="text"
            :class="{ 'form-error': isCategoryNameTooLong }"
            placeholder="Enter Category Name"
            v-model="category.name"
            @input="isNameNotUnique ? resetIsNameNotUnique() : undefined"
          />
          <p v-if="isCategoryNameTooLong" class="mt-3 mb-0 text-alert-danger">
            Please use a shorter name.
          </p>
          <p v-if="isNameNotUnique" class="mt-3 mb-0 text-alert-danger">
            Please use a name you have not already used.
          </p>
          <div class="custom-tags flex md:space-x-2">
            <div v-for="color in colors" :key="color" class="mb-2">
              <label>
                <input data-cy="category-modal-color-radio" type="radio" v-model="category.color" :value="color" />
                <span class="circle" :style="{ 'background-color': color }"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="actions">
          <base-button
            data-cy="category-modal-add-button"
            class="button primary-button small-button ml-auto"
            :class="[
              {
                pointer: !canFormBeSubmitted,
              },
            ]"
            @click="saveNewCategory"
            :disabled="!canFormBeSubmitted"
          >
            <span v-if="!isSaving">Add Category</span>
            <span v-else>Saving..</span>
          </base-button>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import dao from '@/dao';
  import ValidateCategoryMixin from '@/views/Mixins/ValidateCategoryMixin';

  export default {
    data() {
      return this.initialState();
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['userCategories']),
    },
    watch: {
      show: function (show) {
        if (!show) {
          return;
        }
      },
    },
    methods: {
      reset() {
        this.isSaving = false;
        this.category.name = '';
        this.category.color = '';
        this.addingNewCategory = false;
      },
      close() {
        this.$emit('close');
      },
      initialState() {
        return {
          isSaving: false,
          category: {
            name: '',
            color: '',
          },
          colors: [
            '#CBD5E0',
            '#718096',
            '#F56565',
            '#ED8936',
            '#DD6B20',
            '#ECC94B',
            '#48BB78',
            '#38B2AC',
            '#667EEA',
            '#9F7AEA',
          ],
        };
      },
      async saveNewCategory() {
        if (!this.canFormBeSubmitted) {
          return;
        }
        this.isSaving = true;
        if (await this.categoryWithSameNameExists(this.category.name)) {
          this.isSaving = false;
          return;
        }

        const shouldEnableCategory = !this.didUserReachCategoriesLimit;

        return dao.categories
          .create(
            this.category.name.trim(),
            this.category.color,
            shouldEnableCategory,
            this.userProfile.uid,
          )
          .then(() => {
            this.$eventStore.createPostCategory();
            this.reset();
            this.close();
          });
      },
    },
    mixins: [ValidateCategoryMixin],
    name: 'new-category-modal',
    props: {
      show: Boolean,
    },
  };
</script>

<style>
  @supports (padding: max(0px)) {
    form {
      padding-bottom: env(safe-area-inset-bottom) !important;
    }
  }
  input[type='radio']:checked .circle {
    border: solid 2px transparent;
  }
  input[type='radio']:checked + .circle {
    border: solid 2px var(--color-secondary);
  }
  .form-control.form-error,
  .form-control.form-error:focus {
    border-color: rgb(245, 101, 101);
  }
</style>
