<template>
  <modal :show="show" @close="close">
    <div data-cy="category-modal-header" slot="header" class="modal-title">
      Edit Category
    </div>

    <div>
      <form role="form" @submit.prevent="updateCategory" class="needs-validation">
        <div class="space-y-3">
          <input
            data-cy="category-modal-input"
            class="form-control"
            autofocus
            type="text"
            placeholder="Enter Category Name"
            v-model="category.name"
            @input="isNameNotUnique ? resetIsNameNotUnique() : undefined"
          />
          <p v-if="isCategoryNameTooLong" class="mt-3 mb-0 text-alert-danger">
            Please use a shorter name.
          </p>
          <p v-if="isNameNotUnique" class="mt-3 mb-0 text-alert-danger">
            Please use a name you have not already used.
          </p>
          <div class="custom-tags flex md:space-x-2">
            <div v-for="color in colors" :key="color">
              <label>
                <input data-cy="category-modal-color-radio" type="radio" v-model="category.color" :value="color" />
                <span class="circle" :style="{ 'background-color': color }"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="actions">
          <base-button
            data-cy="category-modal-save-button"
            class="button primary-button small-button ml-auto"
            :class="[
              {
                pointer: !canFormBeSubmitted,
              },
            ]"
            @click="updateCategory"
            :disabled="!canFormBeSubmitted"
          >
            <span v-if="isSaving">Saving..</span>
            <span v-else>Save</span>
          </base-button>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import dao from '@/dao';
  import ValidateCategoryMixin from '@/views/Mixins/ValidateCategoryMixin';

  export default {
    data() {
      return this.initialState();
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['userCategories']),
    },
    watch: {
      show: function (show) {
        if (!show) {
          return;
        }
      },
      selectedCategory: {
        handler: function (newVal) {
          if (!newVal) {
            return;
          }
          this.category.name = newVal.name;
          this.category.color = newVal.color;
        },
      },
    },
    methods: {
      close() {
        this.$emit('close');
      },
      initialState() {
        return {
          isSaving: false,
          category: {
            name: '',
            color: '',
          },
          colors: [
            '#CBD5E0',
            '#718096',
            '#F56565',
            '#ED8936',
            '#DD6B20',
            '#ECC94B',
            '#48BB78',
            '#38B2AC',
            '#667EEA',
            '#9F7AEA',
          ],
        };
      },
      async updateCategory() {
        if (!this.canFormBeSubmitted) {
          return;
        }
        this.isSaving = true;
        const hasSameNameCategory = await this.categoryWithSameNameExists(
          this.category.name,
          this.selectedCategory.id
        );
        if (hasSameNameCategory) {
          this.isSaving = false;
          return;
        }
        await dao.categories.update(
          this.selectedCategory.id,
          this.category.name.trim(),
          this.category.color
        );
        this.isSaving = false;
        this.close();
      },
    },
    name: 'edit-category-modal',
    mixins: [ValidateCategoryMixin],
    props: {
      show: Boolean,
      selectedCategory: Object,
    },
  };
</script>

<style>
  @supports (padding: max(0px)) {
    form {
      padding-bottom: env(safe-area-inset-bottom) !important;
    }
  }
</style>
