<template>
  <modal :show="show" @close="close" :class="[{ 'overlay--on': submitting }]">
    <div class="overlay" v-if="submitting">
      <div class="loader">
        <span class="loading loading-lg"
          ><inline-svg src="/img/icons/loading.svg"></inline-svg
        ></span>
      </div>
    </div>

    <div slot="header" class="modal-title" data-cy="recurrent-post-modal-title">
      <span v-if="existingPost">Edit Recurrent Post</span>
      <span v-else>Add a New Recurrent Post</span>
    </div>

    <div class="space-y-6">
      <div class="space-y-3" v-if="userCategories">
        <span class="font-bold">Choose Category</span>
        <select
          data-cy="recurrent-post-category-select"
          class="form-control"
          v-show="userCategories.length > 0"
          v-model="event.category"
        >
          <option v-for="category in userCategories" :key="category.id" :value="category.id">{{
            category.name
          }}</option>
        </select>
        <p v-if="userCategories.length == 0">
          You don't have any categories yet. Please create one.
        </p>
        <new-category-mini-form
          :show="userCategories.length === 0"
          @new-category-added="autoSelectNewCategory"
        />
      </div>
      <div class="space-y-3">
        <div class="flex justify-between">
          <span class="font-bold">Select Day{{ !existingPost ? '(s)' : '' }}</span>
          <div class="space-x-3" v-if="!existingPost">
            <a
              data-cy="recurrent-post-select-all-link"
              href="#"
              class="text-xs"
              @click.prevent="selectAllDays"
              :disabled="event.days.length == 7"
              >SELECT ALL</a
            >
            <a
              data-cy="recurrent-post-clear-link"
              href="#"
              class="text-xs"
              @click.prevent="clearAllDays"
              :disabled="event.days.length == 0"
              >CLEAR</a
            >
          </div>
        </div>
        <div class="flex flex-wrap mt-6" v-if="existingPost">
          <div class="custom-tags" v-for="(day, index) in weekdays" :key="index">
            <label>
              <input data-cy="recurrent-post-day-checkbox" type="radio" :value="index + 1" v-model="event.day" />
              <span>{{ day | formatDay }}</span>
            </label>
          </div>
        </div>
        <div class="flex flex-wrap mt-6" v-else>
          <div class="custom-tags" v-for="(day, index) in weekdays" :key="index">
            <label>
              <input data-cy="recurrent-post-day-checkbox" type="checkbox" :value="index + 1" v-model="event.days" />
              <span>{{ day | formatDay }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="space-y-3">
        <div class="mb-4 font-bold">Post At</div>
        <div v-for="(time, index) in event.times" :key="index" class="flex items-center space-x-3">
          <div class="date-picker inline flex-1">
            <inline-svg src="/img/icons/history.svg"></inline-svg>
            <base-input data-cy="recurrent-post-time-input" type="time" v-model="event.times[index]" />
          </div>
          <tooltip content="Remove time slot" v-if="event.times.length > 1">
            <base-button
              data-cy="recurrent-post-remove-slot-button"
              class="dark-mode-bg outline-none focus:outline-none flex h-8 w-8 items-center justify-center rounded-full bg-red-100 text-alert-danger"
              @click="removeTimeSlot(index)"
            >
             <inline-svg class="p-1" src="img/icons/close.svg"/>
            </base-button>
          </tooltip>
          <tooltip content="Add time slot" v-if="!existingPost">
            <base-button
              data-cy="recurrent-post-add-slot-button"
              type="secondary"
              class="dark-mode-bg outline-none focus:outline-none flex h-8 w-8 items-center justify-center rounded-full bg-main-color-50 text-main-color-100"
              @click="addNewTimeSlot"
              :disabled="!canAddNewTimeSlots"
            >
              <inline-svg src="img/icons/add.svg"/>
            </base-button>
          </tooltip>
        </div>
      </div>
      <div class="actions">
        <div class="flex justify-between items-center w-full" v-if="!existingPost">
          <span v-show="canSubmit">{{ newPostsEstimate }} slots will be scheduled</span>
          <button
            data-cy="recurrent-post-add-button"
            class="button custom-button small-button ml-auto inline"
            :disabled="submitting || !canSubmit"
            @click="createRecurrentPost"
          >
            Add Recurrent Post
          </button>
        </div>
        <div class="sm:flex-no-wrap flex w-full flex-wrap justify-between gap-y-3" v-else>
          <button
            data-cy="recurrent-post-delete-button"
            class="button small-button dark-mode-bg-60 bg-red-100 text-red-500"
            @click="deleteRecurrentPost"
          >
            Delete
          </button>
          <button
            data-cy="recurrent-post-update-button"
            class="button small-button custom-button"
            @click="updateRecurrentPost"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
  const fb = require('../../firebase.js');
  import { mapGetters, mapState } from 'vuex';
  import NewCategoryMiniForm from '../../components/NewCategoryMiniForm';
  import { formatTimeFromHoursAndMinutes } from '../../util/formatTime';
  import dao from '@/dao';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';

  export default {
    props: {
      show: Boolean,
      day: Number,
      hour: Number,
      existingPost: Object,
    },
    components: {
      NewCategoryMiniForm,
    },
    filters: {
      formatDay(day) {
        return day.slice(0, 3);
      },
    },
    watch: {
      show: {
        immediate: true,
        handler: function (newVal) {
          if (this.existingPost) {
            return;
          }
          if (!newVal) {
            this.event = this.getBaseEvent();
            return;
          }
          this.$nextTick(function () {
            this.event.days.push(this.day);
            this.event.times = [formatTimeFromHoursAndMinutes(this.hour, 0)];
          });
        },
      },
      existingPost: function (newVal) {
        if (!newVal) {
          return;
        }
        this.event.day = newVal.day;
        this.event.times = [newVal.time];
        this.event.category = newVal.category.id;
      },
    },
    computed: {
      canSubmit() {
        return (
          this.event.category !== null &&
          this.event.days.length > 0 &&
          this.event.times.length &&
          this.event.times[0]
        );
      },
      canAddNewTimeSlots() {
        return !this.existingPost && this.event.times.length < 24;
      },
      newPostsEstimate() {
        return this.event.times.length * this.event.days.length;
      },
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['userCategories']),
    },
    data() {
      return {
        weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        submitting: false,
        event: this.getBaseEvent(),
      };
    },
    methods: {
      addNewTimeSlot() {
        if (!this.canAddNewTimeSlots) {
          return;
        }
        const lastTime = this.event.times[this.event.times.length - 1];
        const newHour = parseInt(lastTime.split(':')[0]) + 1;
        this.event.times.push(formatTimeFromHoursAndMinutes(newHour < 24 ? newHour : 0, 0));
      },
      autoSelectNewCategory(categoryId) {
        this.event.category = categoryId;
      },
      selectAllDays() {
        this.event.days = [1, 2, 3, 4, 5, 6, 7];
      },
      clearAllDays() {
        this.event.days = [];
      },
      close() {
        this.event = this.getBaseEvent();
        this.$emit('close');
      },
      getBaseEvent() {
        return {
          day: 1,
          times: ['00:00'],
          category: null,
          days: [],
        };
      },
      removeTimeSlot(index) {
        this.event.times.splice(index, 1);
      },
      async updateRecurrentPost() {
        this.submitting = true;
        await dao.recurrentPosts.update(
          this.existingPost.id,
          this.event.day,
          this.event.times[0],
          this.event.category
        );
        this.close();
        this.submitting = false;
      },
      deleteRecurrentPost() {
        this.swalModal({
          title: `Delete Recurrent Post`,
          text: `Are you sure you want to delete this recurrent post?`,
          type: 'question',
          showCancelButton: true,
          confirmButtonText: 'Delete Recurrent Post',
          preConfirm: async () => {
            this.submitting = true;
            try {
              await dao.recurrentPosts.delete(this.existingPost.id);
              this.submitting = false;
            } catch (error) {
              this.swalModal({
                title: 'Sorry',
                text: 'An error occurred while deleting a recurrent post.',
                type: 'warning',
              });
            }
            this.close();
          },
        });
      },
      async createRecurrentPost() {
        if (!this.event.category) {
          return;
        }

        this.submitting = true;
        const categoryRef = fb.categoriesCollection.doc(this.event.category);
        const userRef = fb.usersCollection.doc(this.userProfile.uid);
        const docs = [];

        for (let day of this.event.days) {
          for (let time of this.event.times) {
            docs.push({
              day: day,
              time: time,
              category: categoryRef,
              userRef: userRef,
            });
          }
        }

        await dao.recurrentPosts.createMultiple(docs);
        this.$eventStore.createRecurrentPost();
        this.close();
        this.submitting = false;
      },
    },
    mixins: [SwalModalMixin],
  };
</script>
