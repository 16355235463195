<template>
  <div class="weekly-schedule-wrapper overflow-x-auto">
    <table class="weekly-schedule-table table-fixed w-full h-full">
      <thead>
        <tr>
          <th class="border border-table"></th>
          <th
            class="border border-table pt-4 pb-5 leading-none"
            :key="weekday"
            v-for="weekday in weekdays"
          >
            {{ weekday }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="border border-table" v-for="hour in activeHours" :key="hour">
          <td class="text-center">{{ formatTimeWithUserLocale(formatHour(hour)) }}</td>
          <weekly-schedule-slot
            v-for="dayIndex in weekdays.length"
            :events="getDayAndHourEvents(dayIndex, hour)"
            :dayIndex="dayIndex"
            :hour="hour"
            :key="hour + '_' + dayIndex"
            @edit-event="editEvent"
            @event-dragged="eventDragged"
            @event-moved="eventMoved"
            @cell-selected="selectCell"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import moment from 'moment';
  import TimeFormatterMixin from '@/views/Mixins/TimeFormatterMixin';
  import WeeklyScheduleSlot from './WeeklyScheduleSlot';

  export default {
    props: {
      events: Array,
      showOnlyFilledHours: Boolean,
    },
    components: {
      WeeklyScheduleSlot,
    },
    data() {
      return {
        weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        hours: [
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20,
          21,
          22,
          23,
          0,
          1,
          2,
          3,
          4,
          5,
          6,
        ],
        draggedSlot: null,
        draggedEvent: null,
      };
    },
    computed: {
      activeHours() {
        if (!this.showOnlyFilledHours) {
          return this.hours;
        }
        const eventHours = this.events.map((event) => {
          const eventHour = parseInt(event.time.split(':')[0]);
          return eventHour;
        });
        const unique = [...new Set(eventHours)].sort((a, b) => a - b);
        return this.hours.filter((hour) => unique.includes(hour));
      },
    },
    methods: {
      eventDragged(event) {
        this.draggedEvent = event;
      },
      editEvent(event) {
        this.$emit('event-selected', event.original);
      },
      eventMoved(hour, dayIndex) {
        this.$emit('event-moved', this.draggedEvent, hour, dayIndex);
      },
      formatHour(hour) {
        return moment({ hour: hour });
      },
      getDayAndHourEvents(day, hour) {
        return this.events
          .filter((event) => {
            const eventHour = event.time.split(':')[0];
            return event.day == day && eventHour == hour;
          })
          .sort((a, b) => {
            return a.time < b.time ? -1 : 1;
          });
      },
      selectCell(day, hour) {
        this.$emit('cell-selected', day, hour);
      },
    },
    mixins: [TimeFormatterMixin],
  };
</script>

<style lang="scss">
  .weekly-schedule-wrapper {
    overflow-x: auto;
    max-width: 100%;
  }
  .weekly-schedule-table {
    min-width: 650px;
  }
  .new {
    @apply opacity-0;

    &:hover {
      @apply opacity-100;
    }
  }
  thead {
    th {
      @apply text-center;
      font-size: 0;
      background-color: var(--color-dark);

      @screen lg {
        @apply text-sm;
      }

      &::first-letter {
        @apply text-sm;
      }
    }
  }
  tbody {
    tr:last-child {
      td {
        @apply border-solid;
      }
      @apply rounded-b-sm overflow-hidden;
    }
  }

  .weekly-schedule-table > tbody > tr > td:first-child {
    background-color: var(--color-dark);
  }
</style>
